<template>
  <v-menu
    v-model="showMenu"
    :position-x="x"
    :position-y="y"
    min-width="200px"
    max-width="300px"
    absolute
    offset-y
  >
    <component
      :is="currentComponent"
      :item="item"
      @closeMenu="closeMenu"
    />
  </v-menu>
</template>

<script>
    import { contextMenu } from '@/main.js'
    export default {
        components: {
            UsersContext: () => import('@/components/people/users/UsersContext'),
            SellersContext: () => import('@/components/people/sellers/SellersContext'),
            AgenciesContext: () => import('@/components/people/agencies/AgenciesContext'),
            CompaniesContext: () => import('@/components/people/companies/CompaniesContext'),
            ClientsContext: () => import('@/components/people/clients/ClientsContext'),
            ProfilesContext: () => import('@/components/people/profiles/ProfilesContext'),
            VendorsCardContext: () => import('@/components/stock/products/form-sections/VendorsCardContext'),

            ProductsContext: () => import('@/components/stock/products/ProductsContext'),
            CategoriesContext: () => import('@/components/stock/categories/CategoriesContext'),
            NcmContext: () => import('@/components/stock/ncm/NcmContext'),
            ColorsContext: () => import('@/components/stock/colors/ColorsContext'),
            PrintContext: () => import('@/components/stock/print/PrintContext'),
            FactorContext: () => import('@/components/stock/factor/FactorContext'),
            VendorsContext: () => import('@/components/stock/vendors/VendorsContext'),
            CompositionContext: () => import('@/components/stock/composition/CompositionContext'),

            BudgetsContext: () => import('@/components/comercial/budgets/BudgetsContext'),
            OrdersContext: () => import('@/components/comercial/orders/OrdersContext'),
            CrmContext: () => import('@/components/comercial/crm/CrmContext'),
            OrderProductsContext: () => import('@/components/comercial/order-products/OrderProductsContext'),
            
            NewsletterContext: () => import('@/components/newsletter/NewsletterContext'),
        },
        data: () => ({
            currentComponent: null,
            item: null,
            showMenu: false,
            x: 0,
            y: 0,
        }),
        created() {
            contextMenu.$on('openContextMenu', (event, item, context) => {
                event.preventDefault()
                this.item = item
                this.currentComponent = context
                this.x = event.clientX
                this.y = event.clientY
                this.showMenu = false
                this.$nextTick(() => {
                    this.showMenu = true
                })
            })
        },
        methods: {
            closeMenu(){
                this.showMenu = false
            }
        },
    }
</script>

<style lang="scss">
    .u-text-overflow {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
</style>